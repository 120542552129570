import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { FaUser } from 'react-icons/fa'
import { register as registerUser, reset } from '../features/auth/authSlice'
import { useForm } from "react-hook-form"


const Register = () => {
  const { register, formState: { errors }, handleSubmit, watch } = useForm()

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
  })

  const { name, email, password, password2 } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    state => state.auth
  )

  useEffect(() => {
    if(isError) {
      toast.error(message)
    }

    if(isSuccess || user) {
      navigate('/')
    }

    dispatch(reset())

  }, [user, isError, isSuccess, message, navigate, dispatch])

  useEffect(() => {
    if(user) {
      toast.success('Verify your email')
    }
  }, [user])

  // const onChange = (e) => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [e.target.name]: e.target.value
  //   }))
  // }
  
  const onSubmit = userData => {
    if(userData.password !== userData.password2) {
      toast.error('Passwords do not match')
    } else {
      delete userData['password2']
      dispatch(registerUser(userData))
    }
  }
  // const onSubmit = (e) => {
  //   e.preventDefault()
  //   if(password !== password2) {
  //     toast.error('Passwords do not match')
  //   } else {
  //     const userData = {
  //       name,
  //       email,
  //       password
  //     }

  //     dispatch(register(userData))
  //   }
  // }
  
  return (
    <div className="dark:bg-gray-900">
    <div className="w-full max-w-md min-h-screen mx-auto pt-20 dark:bg-gray-900 dark:text-white">
      <div className="flex flex-col items-center">
        <FaUser style={{ fontSize: 40 }} className="mx-auto"/>
        <span className="text-2xl mb-2">Register</span>
      </div>
      {/* <form onSubmit={onSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 dark:bg-gray-800">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-white">
            Enter your name
          </label>
          <input onChange={onChange} type="text" id="name" name="name" value={name} placeholder="John Lim" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light"/>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-white">
            Enter your email
          </label>
          <input onChange={onChange} type="text" id="email" name="email" value={email} placeholder="optimum@gmail.com" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light"/>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-white">
            Enter your password
          </label>
          <input onChange={onChange} type="password" id="password" name="password" value={password} placeholder="******************" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light"/>
        </div>
        <div className="mb-2">
          <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-white">
            Confirm your password
          </label>
          <input onChange={onChange} type="password" id="password2" name="password2" value={password2} placeholder="******************" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light"/>
        </div>
        <div className="flex items-center justify-between">
          <button className="flex items-center py-3 px-5 mt-4 mx-auto text-sm font-medium text-center text-white rounded-lg bg-blue-600 sm:w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-primary-600 dark:hover:bg-blue-600 dark:focus:ring-blue-800" type="submit">
            {isLoading?<div className="mx-1 w-4 h-4 border-2 border-t-blue-600 rounded-full animate-spin"></div>:<></>}
            <span className="mx-1">{`${isLoading? "Registering" : "Register"}`}</span>
          </button>
        </div>
      </form> */}
      <form onSubmit={handleSubmit(onSubmit)} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 dark:bg-gray-800">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-white">
            Enter your name
          </label>
          <input {...register("name", {required: "Required", maxLength: 30})} placeholder="John Lim" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light"/>
          {errors.name?.type === 'required' && <p className="text-red-700 text-sm pl-2 pt-1">Required</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-white">
            Enter your email
          </label>
          <input {...register("email", {required: "Required", pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address"}})} placeholder="optimum@gmail.com" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light"/>
          {errors.email && <p className="text-red-700 text-sm pl-2 pt-1">{errors.email?.message}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-white">
            Enter your password
          </label>
          <input {...register("password", {required: true, minLength: {value: 8, message: "Password too short"}, maxLength: {value: 20, message: "Password too long"}, pattern: {value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i, message: "Password must contain a minimum of eight characters with at least one letter and one number"}})} type="password" placeholder="******************" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light"/>
          {errors.password && <p className="text-red-700 text-sm pl-2 pt-1">{errors.password?.message}</p>}
        </div>
        <div className="mb-2">
          <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-white">
            Confirm your password
          </label>
          <input {...register("password2", {required: true, minLength: 8, maxLength: 20, validate: (val) => {if (watch('password') !== val) {return "Your passwords do not match"}},})} type="password"  placeholder="******************" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light"/>
          {errors.password2 && <p className="text-red-700 text-sm pl-2 pt-1">{errors.password2?.message}</p>}
        </div>
        <button className="flex items-center py-3 px-5 mt-4 mx-auto text-sm font-medium text-center text-white rounded-lg bg-blue-600 sm:w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-primary-600 dark:hover:bg-blue-600 dark:focus:ring-blue-800" type="submit">
          {isLoading?<div className="mx-1 w-4 h-4 border-2 border-t-blue-600 rounded-full animate-spin"></div>:<></>}
          <span className="mx-1">{`${isLoading? "Registering" : "Register"}`}</span>
        </button>
      </form>
      <p className="text-center text-gray-500 text-xs">
        &copy;2022 Optimum Aerospace. All rights reserved.
      </p>
    </div>
  </div>
  )
}

export default Register