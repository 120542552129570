import { Link } from 'react-router-dom'
import Dropdown from './Dropdown'

const Navbar = () => {
  const submenu1 = [{title: "Profile", url: "/about"}, {title: "Certificates", url: "/certificates"}]
  const submenu2 = [{title: "Parts Availability", url: "/partsavailability"}, {title: "Repair, Overhaul, Exchange", url: "/repair"}]
  return (
    <div className="font-poppins hidden text-lg text-white lg:flex flex-row justify-center bg-black h-[50px]">
        <div className='flex flex-col pl-8 pr-16 justify-center relative'>
        <Dropdown menu={"Home"} submenus={submenu1}/>
        </div>
        <div className='flex flex-col pr-16 justify-center relative'>
        <Dropdown menu={"Services"} submenus={submenu2}/>
        </div>
        <Link to='/blogs' className="group hover:text-blue-300 pr-16 flex flex-col justify-center items-center">
          <span>Blogs</span>
          <div className="w-0 mx-auto group-hover:w-full hover:w-full h-1 bg-white transition-all duration-200 ease-in-out"></div>
        </Link>
        <Link to='/contact' className="group hover:text-blue-300 pr-16 flex flex-col justify-center items-center">
          Contact
        <div className="w-0 mx-auto group-hover:w-full hover:w-full h-1 bg-white transition-all duration-200 ease-in-out"></div>
        </Link>
    </div>
  )
}

export default Navbar