import { FaRegEnvelope, FaPhoneAlt, FaRegBookmark, FaList, FaTimes } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { useState, useRef, useEffect } from 'react'
import { toast } from "react-toastify"
import OAS_Logo_Transparent from '../assets/OAS_Logo_Transparent.png'

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {user} = useSelector(state => state.auth)

  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    toast.success('Logged out')
    navigate('/')
  }

  const [menuOpen, setMenuOpen] = useState(false)

  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
          setIsComponentVisible(false);
          setMenuOpen(false);
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);


  return (
    <div className="bg-gray-200 border-black border-b-2 dark:bg-gray-800">
        <div className='max-w-screen-2xl py-3 mx-auto dark:bg-gray-800 dark:text-white'>
            <div className='text-xs flex flex-row justify-around lg:text-base'>
                <div className="mr-auto ml-2 my-auto lg:mx-0">
                    {/* <Link to='/'><FaPlaneDeparture style={{ fontSize: 40 }} /></Link> */}
                    <Link to='/'><img src={OAS_Logo_Transparent} alt="logo" className="w-[250px]"/></Link>
                </div>
                <div className='flex flex-col lg:flex-row'>
                    <div className='flex my-auto'>
                        <div><FaPhoneAlt className='mt-1 mr-2' color="deepskyblue"/></div>
                        <div className='flex flex-col mx-2'>
                            <div className='font-bold'>PHONE</div>
                            <div>+65 6875 2920</div>
                        </div>
                    </div>
                    {/* <li className="hidden lg:inline-block"><FaPhoneSquareAlt style={{ color: '#5dbea3', fontSize: 40 }}/></li> */}
                    <div className='flex my-auto'>
                        <div><FaRegEnvelope className='mt-1 mr-2' color="deepskyblue"/></div>
                        <div className='flex flex-col mx-2'>
                            <div className='font-bold'>EMAIL</div>
                            <div>sales@optimumaerospace.com</div>
                        </div>
                    </div>
                    <div className='flex my-auto'>
                        <div><FaRegBookmark className='mt-1 mr-2' color="deepskyblue"/></div>
                        <div className='flex flex-col mx-2'>
                            <div className='font-bold'>AOG (24/7)</div>
                            <div>+65 9682 1464</div>
                        </div>
                    </div>
                </div>
                {!isComponentVisible && !menuOpen && <div ref={ref} className="inline-block mr-8 ml-auto lg:hidden my-auto"><FaList onClick={()=>{setMenuOpen(!menuOpen); setIsComponentVisible(true)}} style={{ fontSize: 24 }} className="cursor-pointer"/></div>}
                {isComponentVisible && menuOpen && <div ref={ref} className="inline-block mr-8 ml-auto lg:hidden my-auto"><FaTimes onClick={()=>{setMenuOpen(!menuOpen); setIsComponentVisible(false)}} style={{ fontSize: 24 }} className="cursor-pointer"/></div>}
                {/* <li className="hidden lg:inline-block"><FaEnvelopeSquare style={{ color: '#4681f4', fontSize: 40 }}/></li> */}
                <div className="hidden lg:inline-block my-auto">
                    <Link to='/contact'>
                        <button className="bg-primary-blue text-white font-bold hover:bg-green-400 py-2 px-4 rounded-2xl">
                            Enquire Now
                        </button>
                    </Link>
                </div>
                {/* {user ? (
                    <li className="hidden lg:inline-block">
                        <button onClick={onLogout}>
                            <FaSignOutAlt style={{ fontSize: 30 }} />
                        </button>
                    </li>
                ) : (
                    <>  
                        <li className="hidden lg:inline-block" >
                            <Link to='/login'><FaSignInAlt style={{ fontSize: 30 }} /></Link>
                        </li>
                        <li className="hidden lg:inline-block">
                            <Link to='/register'><FaUser style={{ fontSize: 30 }}/></Link>
                        </li>
                    </>
                )} */}
            </div>
        </div>
        <div ref={ref}>
            <ul className={`bg-gray-200 dark:bg-gray-800 dark:text-white absolute w-full mx-auto left-0 right-0 z-[-1] transition-all duration-100 ease-in top-[121px] ${(isComponentVisible && menuOpen)? 'opacity-100 z-10':'opacity-0'}`}>
                <li className="my-10 text-center text-xl font-bold" onClick={()=>setMenuOpen(false)}><Link to='/about' className='hover:text-gray-500'>Profile</Link></li>
                <li className="my-10 text-center text-xl font-bold" onClick={()=>setMenuOpen(false)}><Link to='/certificates' className='hover:text-gray-500'>Certificate</Link></li>
                <li className="my-10 text-center text-xl font-bold" onClick={()=>setMenuOpen(false)}><Link to='/partsavailability' className='hover:text-gray-500'>Parts Availability</Link></li>
                <li className="my-10 text-center text-xl font-bold" onClick={()=>setMenuOpen(false)}><Link to='/contact' className='hover:text-gray-500'>Contact</Link></li>
            </ul>
       </div>
    </div>
  )
}

export default Header