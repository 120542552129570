import Navbar from '../components/Navbar'
import Footer from "../components/Footer"
import parts1 from '../assets/parts1.jpg'
import parts2 from '../assets/parts2.jpg'
import parts3 from '../assets/parts3.jpg'
import parts4 from '../assets/parts4.jpg'

const PartsAvailability = () => {
  return (
    <div>
        <div className='dark:bg-gray-900 dark:text-white'>
            <Navbar />
            <div className="h-[300px] bg-[url('/src/assets/home.jpg')] bg-no-repeat bg-cover bg-center bg-gray-500/75 bg-blend-multiply pb-5 mx-auto flex flex-col justify-center">
                <span className="text-white text-3xl font-bold ml-12">SERVICES</span>
                <span className="text-sm ml-14 mt-2 text-gray-200">Home {'>'} Services</span>
            </div>
            <div className='max-w-screen-2xl py-8 flex flex-col items-center mx-auto'>
                <div className="flex flex-col justify-around items-center lg:flex-row">
                    <div className='flex flex-col mx-8'>
                        <span className='text-xl font-bold mt-4'>PARTS AVAILABILITY</span><br/>
                        <span className='text-base my-4'>
                          Parts availability for airplanes is an important service we offer our customers. We understand that it could be difficult to purchase parts when they are needed, which is why we have been here for over 20 years to provide our customers with top-notch and a wide range of inventory with complete traceability at an affordable price. This way, our customers are able to keep their own aircraft up and running without having to purchase from unknown sources when aircraft parts are already expensive to buy.
                          <br/><br/>
                          Our service ensures that customers have all the parts they need to keep their planes operating smoothly and safely. We make it easy for customers to enquire by including a Whatsapp button on the website which will be there for you 24/7. By providing us with the part number of what you need, we will get back to you as soon as possible, making the process hassle-free.
                          <br/><br/>
                          Our customers are always impressed by how easy we make it for them to find and buy the parts they need for their aircraft. They tell us all the time about how pleased they are with our service and how convenient it is for them to not have to worry about sourcing parts themselves whenever they need to repair their planes. This is one of the main reasons why we are so well known in this industry and why so many people come to us when they need to purchase replacement parts for their planes.
                        </span>
                        <div className='flex justify-between items-center flex-col my-8 lg:flex-row'>
                          <img src={parts1} alt="Parts1" className="w-4/5 my-4 lg:w-1/5 lg:inline-block lg:mx-4 shadow-xl"/>
                          <img src={parts2} alt="Parts2" className="w-4/5 my-4 lg:w-1/5 lg:inline-block lg:mx-4 shadow-xl"/>
                          <img src={parts3} alt="Parts3" className="w-4/5 my-4 lg:w-1/5 lg:inline-block lg:mx-4 shadow-xl"/>
                          <img src={parts4} alt="Parts4" className="w-4/5 my-4 lg:w-1/5 lg:inline-block lg:mx-4 shadow-xl"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default PartsAvailability