import gmf from '../assets/gmf.png'
import pelita from '../assets/pelita.png'
import namair from '../assets/namair.png'
import sriwijaya_air from '../assets/sriwijaya_air.png'
import tba from '../assets/tba.jpg'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

const Second = () => {
  const slideLeft = () => {
    let slider = document.getElementById('slider')
    if(slider){
      if(window.innerWidth > 600) {
        slider.scrollLeft = slider.scrollLeft - 500
      } else {
        slider.scrollLeft = slider.scrollLeft - 200
      }
    }
  }
  const slideRight = () => {
    let slider = document.getElementById('slider')
    if(slider){
      if(window.innerWidth > 600) {
        slider.scrollLeft = slider.scrollLeft + 500
      } else {
        slider.scrollLeft = slider.scrollLeft + 200
      }
    }
  }

  let currentScroll = 0
  setInterval(() => {
    let slider = document.getElementById('slider')
    if(slider){
      if(window.innerWidth > 600) {
        slider.scrollLeft += 500
        currentScroll += 500
        if(currentScroll - 500 > slider.scrollLeft) {
          slider.scrollLeft = 0
          currentScroll = 0
        }
        } 
        // else {
        //   slider.scrollLeft += 200
        //   currentScroll += 200
        //   if(currentScroll - 200 > slider.scrollLeft) {
        //     slider.scrollLeft = 0
        //     currentScroll = 0
        //   }
        // }
      }
  }, 1500);

  return (
    <div className="dark:bg-gray-900 dark:text-white">
      <div className="max-w-screen-2xl px-16 py-24 mx-auto flex flex-col justify-around dark:bg-gray-900 ">
          <div className="text-3xl font-bold mb-8 mt-auto text-center">
              TRUSTED BY TOP COMPANIES
          </div>
          <FaAngleLeft size={40} className="absolute left-0 cursor-pointer lg:hidden" onClick={slideLeft}/>
            <div id='slider' className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide">
              <img src={gmf} alt="First company" className="h-[120px] inline-block mr-8 shadow-xl"/>
              <img src={pelita} alt="Second company" className="h-[120px] inline-block mx-8 shadow-xl"/>
              <img src={namair} alt="Third company" className="h-[120px] inline-block mx-8 shadow-xl"/>
              <img src={sriwijaya_air} alt="Fourth company" className="h-[120px] inline-block mx-8 shadow-xl"/>
              <img src={tba} alt="Fifth company" className="h-[120px] inline-block ml-8 shadow-xl"/>
            </div>
          <FaAngleRight size={40} className="absolute right-0 cursor-pointer lg:hidden" onClick={slideRight}/>
      </div>
    </div>
  )
}

export default Second