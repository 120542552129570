const Third = () => {
  return (
    <div className="text-white dark:bg-gray-900 shadow-xl">
      <div className="bg-gray-300 max-w-screen-2xl h-[400px] mx-auto flex bg-[url('/src/assets/background2.jpg')] bg-no-repeat bg-cover bg-center bg-gray-900/75 bg-blend-multiply dark:bg-gray-900 justify-center rounded-3xl">
          <div className="p-8 flex flex-col justify-around">
              <div className="text-xl font-extrabold lg:text-6xl">
                  <span>WHAT WE </span>
                  <span className="text-primary-blue">BELIEVE IN</span>
              </div>
              <div className="lg:text-xl lg:px-28">
                  With our <b>extensive network of top-notch & reliable sources of aircraft parts supplies</b>, Optimum Aerospace Supplies is able to provide an inventory of rotables and consumables with <b>complete traceability</b> and documentation coupled with <b>timely delivery</b>. Over the years, we have assisted our customers to procure and lease major aircraft components such as the General Electric CF6-5OC2 engine, auxiliary power units, landing gear and many more.
              </div>
          </div>
      </div>
    </div>
  )
}

export default Third