import { useState, useEffect } from "react"
import { FaSignInAlt, FaUser } from 'react-icons/fa'
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { login, reset } from '../features/auth/authSlice'

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const { email, password } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    state => state.auth
  )

  useEffect(() => {
    if(isError) {
      toast.error(message)
    }

    if(isSuccess || user) {
      navigate('/')
    }

    dispatch(reset())

  }, [user, isError, isSuccess, message, navigate, dispatch])

  useEffect(() => {
    if(user) {
      toast.success(`Welcome ${user.name}`)
    }
  }, [user])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }
  
  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password
    }

    dispatch(login(userData))
  }
  
  return (
    <div className="dark:bg-gray-900">
      <div className="w-full max-w-md min-h-screen mx-auto pt-20 dark:bg-gray-900 text-white">
        <div className="flex flex-col items-center">
          <FaSignInAlt style={{ fontSize: 40 }} className="mx-auto"/>
          <span className="text-2xl mb-2">Login</span>
        </div>
        <form onSubmit={onSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 dark:bg-gray-800">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-white">
              Username
            </label>
            <input onChange={onChange} type="text" id="email" name="email" value={email} placeholder="optimum@gmail.com" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light"/>
          </div>
          <div className="">
            <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-white">
              Password
            </label>
            <input onChange={onChange} type="password" id="password" name="password" value={password} placeholder="******************" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light"/>
          </div>
          <div>
            <a className="inline-block align-baseline font-bold text-sm mr-0 ml-auto text-blue-500 hover:text-blue-800" href="#">
              Forgot Password?
            </a>
          </div>
          <div className="flex items-center justify-between">
            <button className="flex items-center py-3 px-5 mt-4 mx-auto text-sm font-medium text-center text-white rounded-lg bg-blue-600 sm:w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-primary-600 dark:hover:bg-blue-600 dark:focus:ring-blue-800" type="submit">
              {isLoading?<div className="mx-1 w-4 h-4 border-2 border-t-blue-600 rounded-full animate-spin"></div>:<></>}
              <span className="mx-1">{`${isLoading? "Signing In..." : "Sign In"}`}</span>
            </button>
          </div>
        </form>
        <p className="text-center text-gray-500 text-xs">
          &copy;2022 Optimum Aerospace. All rights reserved.
        </p>
      </div>
    </div>
  )
}

export default Login