import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import newsletterService from './newsletterService'

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

// Submit newsletter 
export const submit = createAsyncThunk('newsletter/submit', async (user, thunkAPI) => {
    try {
        return await newsletterService.submit(user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const newsletterSlice = createSlice({
    name: 'newsletter',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(submit.pending, state => {
                state.isLoading = true
            })
            .addCase(submit.fulfilled, state => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(submit.rejected, state => {
                state.isLoading = false
                state.isError = true
            })
    }
})


export const {reset} = newsletterSlice.actions
export default newsletterSlice.reducer