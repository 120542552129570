import { FaChevronRight } from 'react-icons/fa'
import certificate from '../assets/certificate.jpg'

const Sixth = () => {
  return (
    <div className='dark:bg-gray-900 dark:text-white'>
        <div className='max-w-screen-2xl py-8 flex flex-col items-center mx-auto'>
            <div className="text-3xl font-bold">CERTIFICATION</div>
            <div className="flex flex-col justify-around items-center lg:flex-row">
                <img src={certificate} alt="repair" className="w-4/5 my-12 mx-8 lg:w-2/5" loading="lazy"/>
                <div className='flex flex-col mx-8'>
                    <span className='text-xl font-bold'>Aviation Suppliers Association (ASA) Certificate</span><br/>
                    <span className='text-base'>Optimum Aerospace Supplies Pte. Ltd. is accredited by the Aviation Suppliers Association Quality Assurance (ASA) Program ASA-100 and is in conformance with the Federal Aviation Agency (FAA) Advisory Circular AC 00-56.</span>
                    <a href="/contact" className='flex items-center justify-around w-32 mt-16 mx-auto p-2 outline rounded-2xl cursor-pointer hover:bg-green-300'>
                        <span>Enquire now</span>
                        <FaChevronRight />
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Sixth