import Navbar from '../components/Navbar'
import Footer from "../components/Footer"

const Blogs = () => {
  return (
    <div>
        <div className='dark:bg-gray-900 dark:text-white'>
            <Navbar />
            <div className='h-screen text-3xl font-bold italic flex justify-center items-center'>Coming soon...</div>
        </div>
        <Footer/>
    </div>
  )
}

export default Blogs