import { FaCaretDown } from "react-icons/fa"

const Dropdown = ({ menu, submenus }) => {
  return (
    <div className="group">
      <div className='cursor-pointer group-hover:text-blue-300 flex items-center'>
        {menu} 
        <FaCaretDown color="white" className="ml-2 h-4"/>
      </div>
      <div className="w-0 mx-auto group-hover:w-full hover:w-full h-1 bg-white transition-all duration-200 ease-in-out"></div>
      <div className="border-2 rounded-md border-black opacity-0 group-hover:z-10 hover:z-10 flex flex-col top-[100px] group-hover:top-[40px] hover:top-[40px] group-hover:opacity-100 hover:opacity-100 bg-black drop-shadow-lg absolute transition-all duration-200 ease-in-out">
          {submenus.map(submenu => (
          <a href={submenu.url} className="text-sm hover:text-black hover:bg-slate-300 px-3 py-4 break-words" key={submenu.title}>
            {submenu.title}
          </a>
          ))}
      </div>
    </div>
  )
}

export default Dropdown