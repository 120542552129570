import Navbar from './Navbar'
import { useState, useEffect } from 'react'
import home_bg from '../assets/home.webp'

const First = () => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 500)
  }, [])

  return (
    <div className={`bg-gray-500/75 mx-auto flex flex-col shadow-xl transition-all duration-1000 ease-in-out ${loaded?'opacity-100':'opacity-0'}`}>
        <Navbar />
        <img src={home_bg} alt="home" className="object-cover h-[500px]"/>
        <div className={`absolute w-full top-[40%] font-poppins text-3xl text-center text-white flex grow justify-center items-center font-bold lg:text-5xl transition-all duration-1000 ease-in-out ${loaded?'opacity-100 translate-y-0':'opacity-0 -translate-y-10'}`}>
          Your Premier Source of Aerospace Supplies
        </div>
    </div>
    // <div className={`h-[500px] bg-[url('/src/assets/home.jpg')] bg-no-repeat bg-cover bg-center bg-gray-500/75 bg-blend-multiply pb-5 mx-auto flex flex-col shadow-xl transition-all duration-1000 ease-in-out ${loaded?'opacity-100':'opacity-0'}`}>
    //     <Navbar />
    //     <div className={`font-poppins text-3xl text-center text-white flex grow justify-center items-center font-bold lg:text-5xl transition-all duration-1000 ease-in-out ${loaded?'opacity-100 translate-y-0':'opacity-0 -translate-y-10'}`}>
    //       Your Premier Source of Aerospace Supplies
    //     </div>
    // </div>
  )
}

export default First