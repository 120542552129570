import { FaFacebook, FaInstagram, FaLinkedin, FaGoogle, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaGlobe } from 'react-icons/fa'
import OAS_Logo_Transparent from '../assets/OAS_Logo_Transparent.png'
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { submit, reset } from '../features/newsletter/newsletterSlice'

const Footer = () => {
    const [formData, setFormData] = useState({
        email: ''
    })
    
    const { email } = formData

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { isLoading, isError, isSuccess, message } = useSelector(
        state => state.newsletter
    )
    
    useEffect(() => {
        if(isError) {
            toast.error(message)
        }

        if(isSuccess) {
            navigate('/')
            toast.success('Stay tuned for exciting newsletters!')
            setFormData({email: ''})
            window.scrollTo(0, 0)
        }
        dispatch(reset())

        }, [isError, isSuccess, message, navigate, dispatch])
    
    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    
    const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
        email
    }

    dispatch(submit(userData))
    }
    return (
        <div className='flex flex-col bg-gray-900 text-white dark:bg-black'>
            <div className='flex flex-col my-6 justify-around items-center lg:my-4 lg:flex-row'>
                <div className='w-3/4 my-8 lg:w-1/3 lg:mx-8'>
                    <div className='font-bold'>ABOUT OPTIMUM AEROSPACE SUPPLIES</div>
                    <div className='py-8'>We are a leading aircraft support company which combines experience, quality and competitive pricing, making us an ideal source to meet your operational requirement.
                    </div>
                    <div className='flex flex-row'>
                        <a href="https://www.facebook.com" target="_blank" rel="noreferrer"><FaFacebook className='mx-2'/></a>
                        <a href="https://www.instagram.com" target="_blank" rel="noreferrer"><FaInstagram className='mx-2'/></a>
                        <a href="https://www.linkedin.com/company/optimum-aerospace-supplies/" target="_blank" rel="noreferrer"><FaLinkedin className='mx-2'/></a>
                        <a href="https://www.google.com" target="_blank" rel="noreferrer"><FaGoogle className='mx-2'/></a>
                    </div>
                </div>
                <div className='w-3/4 my-8 lg:w-1/3 lg:mx-8'>
                    <span className='font-bold'>GET IN TOUCH</span><br/>
                    <div className='flex flex-row items-center py-2'><FaMapMarkerAlt style={{color:'#49BEDE'}}/><span className='pl-4'>Singapore</span></div>
                    <div className='flex flex-row items-center py-2'><FaPhoneAlt style={{color:'#49BEDE'}}/><span className='pl-4'>+65 6875 2920 / +65 9682 1464 (24/7)</span></div>
                    <div className='flex flex-row items-center py-2'><FaEnvelope style={{color:'#49BEDE'}}/><span className='pl-4'>sales@optimumaerospace.com</span></div>
                    <div className='flex flex-row items-center py-2'><FaGlobe style={{color:'#49BEDE'}}/><span className='pl-4'>optimumaerospace.com</span></div>
                </div>
                <div className='w-3/4 my-8 lg:w-1/3 lg:mx-8'>
                    <span className='font-bold'>E-NEWSLETTER</span><br/>
                    <div className='py-2'>You can subscribe to our newsletter, we will send you about latest projects.</div>
                    <form onSubmit={onSubmit} className="bg-white shadow-md rounded dark:bg-gray-800 flex flex-row">
                        <input onChange={onChange} type="text" id="email" name="email" value={email} placeholder="name@gmail.com" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded border border-gray-300 shadow-sm focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light"/>
                        <button type="submit" className="flex items-center py-3 px-5 mx-auto text-sm font-bold text-center rounded text-white bg-primary-blue sm:w-fit hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-primary-600 dark:hover:bg-blue-500 dark:focus:ring-blue-500">
                            {isLoading?<div className="mx-1 w-4 h-4 border-2 border-t-primary-blue rounded-full animate-spin"></div>:<></>}
                            <span className="">{`${isLoading? "" : "Submit"}`}</span>
                        </button>
                    </form>
                </div>
            </div>
            <div className='bg-gray-600 text-sm flex flex-col items-center lg:flex-row '>
                <span className='text-2xl font-bold font-saudagar m-8'>OPTIMUM AEROSPACE SUPPLIES PTE LTD</span>
                <div className='ml-8'>Copyright 2022. All Rights Reserved.</div>
            </div>
        </div>
    )
}

export default Footer