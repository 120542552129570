import { FaChevronRight } from "react-icons/fa"
import certificate from '../assets/certificate.jpg'
import Footer from "../components/Footer"
import Navbar from '../components/Navbar'

const Certificates = () => {
  return (
    <div>
        <div className='dark:bg-gray-900 dark:text-white'>
            <Navbar />
            <div className="h-[300px] bg-[url('/src/assets/home.jpg')] bg-no-repeat bg-cover bg-center bg-gray-500/75 bg-blend-multiply pb-5 mx-auto flex flex-col justify-center">
                <span className="text-white text-3xl font-bold ml-12">CERTIFICATION</span>
                <span className="text-sm ml-14 mt-2 text-gray-200">Home {'>'} Certificates</span>
            </div>
            <div className='max-w-screen-2xl py-8 flex flex-col items-center mx-auto'>
                <div className="flex flex-col justify-around items-center lg:flex-row">
                    <div className='flex flex-col mx-8'>
                        <span className='text-xl font-bold'>Aviation Suppliers Association (ASA) Certificate</span><br/>
                        <span className='text-base'>Based on the ASA-100 Standard, the ASA Accreditation Program (ASAAP) is a 36-month audit program. The voluntary industry distributor accreditation program, which is outlined in the FAA Advisory Circular (AC) 00-56, is in compliance with the standard. ASA-100 places a strong emphasis on matters like objectivity, skill, and dependability—all issues that are unique to the regulated requirements of the aerospace sector. All of ASA's skilled auditors have expertise working in the aerospace sector for air carriers, repair facilities, manufacturers, and distributors. They also have a deep understanding of the rules governing aviation and commercial aviation business practices. The Aviation Suppliers Association Quality Assurance (ASA) Program ASA-100 has accredited Optimum Aerospace Supplies Pte. Ltd., and it complies with the Federal Aviation Agency (FAA) Advisory Circular AC 00-56.</span>
                        <a href="/contact" className='flex items-center justify-around w-32 mt-16 mx-auto p-2 outline rounded-2xl cursor-pointer hover:bg-green-300'>
                            <span>Enquire now</span>
                            <FaChevronRight />
                        </a>
                    </div>
                    <img src={certificate} alt="repair" className="w-4/5 my-12 mx-8 lg:w-2/5"/>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Certificates