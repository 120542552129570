import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Navbar from '../components/Navbar'
import Footer from "../components/Footer"
import { submit, reset } from '../features/contact/contactSlice'

const Contact = () => {
  const [formData, setFormData] = useState({
    phone: '',
    email: '',
    content: '',
  })

  const { phone, email, content } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isLoading, isError, isSuccess, message } = useSelector(
    state => state.contact
  )

  useEffect(() => {
    if(isError) {
      toast.error(message)
    }

    if(isSuccess) {
      navigate('/')
      toast.success('Message well-received! We will get back to you ASAP')
    }

    dispatch(reset())

  }, [isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      phone,
      email,
      content
    }

    dispatch(submit(userData))
  }
  return (
    <section className="min-h-screen bg-white dark:bg-gray-900">
      <Navbar />
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Contact Us</h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">Got a question? Let us know.</p>
          <form onSubmit={onSubmit} className="space-y-8">
              <div>
                  <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-white">Phone Number</label>
                  <input onChange={onChange} type="text" id="phone" name="phone" value={phone} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light" placeholder="+65 9682 1464" />
              </div>
              <div>
                  <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-white">Email</label>
                  <input onChange={onChange} type="email" id="email" name="email" value={email} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-300 focus:border-blue-300 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300 dark:shadow-sm-light" placeholder="name@company.com" required />
              </div>
              <div className="sm:col-span-2">
                  <label className="block mb-2 text-sm font-bold text-gray-900 dark:text-white">Your message</label>
                  <textarea onChange={onChange} id="content" name="content" value={content} rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-blue-300 focus:border-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-300 dark:focus:border-blue-300" placeholder="Leave a comment..."></textarea>
              </div>
              <button type="submit" className="flex items-center py-3 px-5 mx-auto text-sm font-bold text-center text-white rounded-lg bg-primary-blue sm:w-fit hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-primary-blue dark:hover:bg-blue-500 dark:focus:ring-blue-500">
                {isLoading?<div className="mx-1 w-4 h-4 border-2 border-t-blue-500 rounded-full animate-spin"></div>:<></>}
                <span className="mx-1">{`${isLoading? "Sending" : "Send Message"}`}</span>
              </button>
          </form>
      </div>
      <Footer />
    </section>
  )
}

export default Contact