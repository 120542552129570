import telephone from '../assets/telephone.png'
import headphone from '../assets/headphone.png'
import tick from '../assets/tick.png'


const Fourth = () => {
  return (
    <div className='text-white dark:bg-gray-900'>
        <div className="max-w-screen-2xl py-8 flex flex-col items-center mx-auto rounded-3xl bg-[url('/src/assets/background3.jpg')] bg-no-repeat bg-cover bg-center bg-gray-900/[.9] bg-blend-multiply">
            <div className='font-bold text-2xl lg:text-4xl'>
                Enquire in <span className='text-primary-blue'>3</span> Simple Steps
            </div>
            <div className='text-sm mx-auto flex flex-col justify-between items-center lg:flex-row lg:text-base'>
                <div className='flex flex-col mx-8 lg:w-1/4 lg:mx-8'>
                    <img src={telephone} alt="availability" className="my-4 mx-auto w-28 lg:my-12" loading="lazy"/>
                    <span className='mt-4 h-40 lg:mx-8'>
                        Contact us via:<br/>
                        <ul className="list-disc pl-8">
                            <li>Phone</li>
                            <li>Email</li>
                            <li>Form Enquiries</li>
                        </ul>
                    </span>
                </div>
                <div className='flex flex-col mx-8 lg:w-1/4 lg:mx-8'>
                    <img src={headphone} alt="repair" className="my-4 mx-auto w-28 lg:my-12" loading="lazy"/>
                    <span className='mt-4 h-40 lg:mx-8'>
                        <span>Once we have received your enquiry, we will respond to you as soon as possible and provide an affordable quotation for you.</span>
                    </span>
                </div>
                <div className='flex flex-col mx-8 lg:w-1/4 lg:mx-8'>
                    <img src={tick} alt="unable" className="my-4 mx-auto w-28 lg:my-12" loading="lazy"/>
                    <span className='mt-4 h-40 lg:mx-8'>
                        <span>All aircraft rotables supplied by Optimum Aerospace Supplies will come with the following:</span><br/>
                        <ul className="list-disc pl-8">
                            <li>FAA8130-3</li>
                            <li>EASA FORM 1 or equivalent</li>
                            <li>Vendor Certificate of Conformance <b>(For Consumables)</b></li>
                        </ul>
                    </span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Fourth