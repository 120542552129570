import axios from 'axios'

const API_URL = '/api/contact/'

// Submit form
const submit = async (userData) => {
    const response = await axios.post(API_URL + 'submit', userData)

    if(response.data) {
        console.log('Submitted contact form successfully')
    }

    return response.data
}

const contactService = { submit }

export default contactService