import First from '../components/First'
import Second from '../components/Second'
import Third from '../components/Third'
import Fourth from '../components/Fourth'
import Fifth from '../components/Fifth'
import Sixth from '../components/Sixth'
import Footer from '../components/Footer'
import {FaWhatsapp} from 'react-icons/fa'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import OAS_Logo_Transparent from '../assets/OAS_Logo_Transparent_cropped_192.png'

const Home = () => {
  return (
    <div className=''>
      <First />
      <Second />
      <Third />
      <Fourth />
      <Fifth />
      <Sixth />
      <Footer />
      {/* <a href="https://wa.me/96821464" target="_blank" rel="noreferrer" className='fixed bottom-10 right-10'><FaWhatsapp className="w-10 h-10 lg:w-16 lg:h-16" style={{ color: '#25D366' }}/></a> */}
      <FloatingWhatsApp phoneNumber="96821464" accountName="Optimum Aerospace" allowEsc={true} allowClickAway={true} avatar={OAS_Logo_Transparent}/>
    </div>
  )
}

export default Home