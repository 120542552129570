import Navbar from '../components/Navbar'
import Footer from "../components/Footer"
import parts1 from '../assets/parts1.jpg'
import parts2 from '../assets/parts2.jpg'
import parts3 from '../assets/parts3.jpg'
import parts4 from '../assets/parts4.jpg'

const Repair = () => {
  return (
    <div>
        <div className='dark:bg-gray-900 dark:text-white'>
            <Navbar />
            <div className="h-[300px] bg-[url('/src/assets/home.jpg')] bg-no-repeat bg-cover bg-center bg-gray-500/75 bg-blend-multiply pb-5 mx-auto flex flex-col justify-center">
                <span className="text-white text-3xl font-bold ml-12">SERVICES</span>
                <span className="text-sm ml-14 mt-2 text-gray-200">Home {'>'} Services</span>
            </div>
            <div className='max-w-screen-2xl py-8 flex flex-col items-center mx-auto'>
                <div className="flex flex-col justify-around items-center lg:flex-row">
                    <div className='flex flex-col mx-8'>
                        <span className='text-xl font-bold mt-4'>REPAIR, OVERHAUL & EXCHANGE OF COMPONENTS</span><br/>
                        <span className='text-base my-4'>
                          Airplanes are some of the most advanced machines on the planet. They can travel at high speeds and are used for a variety of tasks, including transporting people and cargo.
                          <br/><br/>
                          When repairing the aircraft, various parts need to be replaced or fixed. These parts are complex and require expert knowledge to install correctly. It is therefore important to use a reputable service provider like Optimum Aerospace Supplies that you can trust to get the job done right the first time. Our company has a wealth of experience in the industry and employs highly skilled staff to ensure our customers receive the best possible service at very competitive prices. We have invested in the latest equipment and machinery to ensure we can offer a fast turnaround on repairs, and we carry out all work to the highest quality standards. - The safety of our customers is our top priority. All of our staff are highly trained and follow strict health and safety procedures when working on aircraft to ensure that safety standards are maintained at all times.
                        </span>
                        <div className='flex justify-between items-center flex-col my-8 lg:flex-row'>
                          <img src={parts4} alt="Parts1" className="w-4/5 my-4 lg:w-1/5 lg:inline-block lg:mx-4 shadow-xl"/>
                          <img src={parts3} alt="Parts2" className="w-4/5 my-4 lg:w-1/5 lg:inline-block lg:mx-4 shadow-xl"/>
                          <img src={parts2} alt="Parts3" className="w-4/5 my-4 lg:w-1/5 lg:inline-block lg:mx-4 shadow-xl"/>
                          <img src={parts1} alt="Parts4" className="w-4/5 my-4 lg:w-1/5 lg:inline-block lg:mx-4 shadow-xl"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Repair