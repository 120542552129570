import first from '../assets/first.jpg'
import businessman from '../assets/businessman.jpg'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'


const About = () => {
  return (
    <div>
        <div className='dark:bg-gray-900 dark:text-white'>
            <Navbar />
            <div className="h-[300px] bg-[url('/src/assets/home.jpg')] bg-no-repeat bg-cover bg-center bg-gray-500/75 bg-blend-multiply pb-5 mx-auto flex flex-col justify-center">
                <span className="text-white text-3xl font-bold ml-12">OUR PROFILE</span>
                <span className="text-sm ml-14 mt-2 text-gray-200">Home {'>'} Profile</span>
            </div>
            <div className='max-w-screen-2xl py-8 flex flex-col items-center mx-auto'>
                <div className="flex flex-col">
                    <div className="flex flex-col items-center mx-8 my-4 lg:flex-row lg:items-start">
                        <div className='w-3/4 my-4 lg:w-1/2 lg:mx-4 lg:pt-4'>
                            <span className='font-bold'>ABOUT OUR COMPANY</span> <br/>
                            Since 2003, Optimum Aerospace Supplies has been able to supply an inventory of rotables and consumables with comprehensive traceability and documentation along with prompt delivery thanks to our large network of excellent and trustworthy sources of aircraft parts supplies. We are a full-service aircraft support provider that combines expertise, dependability, and affordable pricing, making us the perfect choice to satisfy your operational needs. The skilled sales and procurement staff at Optimum Aircraft Supplies, all of whom have extensive airline backgrounds and are highly experienced in purchasing and maintenance processes in the aerospace industry, is responsible for ensuring the company's continuing growth.
                        </div>
                        <div className='w-3/4 my-4 lg:w-1/2 lg:mx-4'>
                            <img src={first} alt="mission" />
                        </div>
                    </div>
                    <div className="flex flex-col-reverse items-center mx-8 my-4 lg:flex-row lg:items-start">
                        <div className='w-3/4 my-4 lg:w-1/2 lg:mx-4'>
                            <img src={businessman} alt="about" />
                        </div>
                        <div className='w-3/4 my-4 lg:w-1/2 lg:mx-4 lg:pt-4'>
                            <span className='font-bold'>COMPANY MISSION</span> <br/>
                            Since 2003, Optimum Aerospace Supplies has been able to supply an inventory of rotables and consumables with comprehensive traceability and documentation along with prompt delivery thanks to our large network of excellent and trustworthy sources of aircraft parts supplies. We are a full-service aircraft support provider that combines expertise, dependability, and affordable pricing, making us the perfect choice to satisfy your operational needs. The skilled sales and procurement staff at Optimum Aircraft Supplies, all of whom have extensive airline backgrounds and are highly experienced in purchasing and maintenance processes in the aerospace industry, is responsible for ensuring the company's continuing growth.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default About