import axios from 'axios'

const API_URL = '/api/newsletter/'

// Submit form
const submit = async (userData) => {
    const response = await axios.post(API_URL + 'submit', userData)

    if(response.data) {
        console.log('Submitted newsletter email successfully')
    }

    return response.data
}

const newsletterService = { submit }

export default newsletterService