import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/Header'
import Home from './pages/Home'
import Contact from './pages/Contact'
import Login from './pages/Login'
import Register from './pages/Register'
import About from './pages/About'
import PartsAvailability from './pages/PartsAvailability'
import Repair from './pages/Repair'
import Certificates from './pages/Certificates'
import Blogs from './pages/Blogs'

function App() {
  return (
    <>
      <Router>
          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/about' element={<About />} />
            <Route path='/certificates' element={<Certificates />} />
            <Route path='/partsavailability' element={<PartsAvailability />} />
            <Route path='/repair' element={<Repair />} />
            <Route path='/blogs' element={<Blogs />} />
          </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
