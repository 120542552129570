import first from '../assets/first.jpg'
import second from '../assets/second.jpg'
import third from '../assets/third.jpg'
import { FaChevronRight } from "react-icons/fa"


const Fourth = () => {
  return (
    <div className='dark:bg-gray-900 dark:text-white'>
        <div className='max-w-screen-2xl py-8 flex flex-col mx-auto'>
            <div className='mx-auto flex flex-col justify-between items-center lg:flex-row'>
                <div className='flex flex-col mx-8 lg:w-1/4'>
                    <img src={first} alt="availability" className="my-12 shadow-xl" loading="lazy"/>
                    <span className='mt-4'>
                        <b>Parts Availability</b><br/>
                        <span>We are able to supply the following:</span>
                        <ul className="list-disc pl-8">
                            <li>Avionics & Instruments</li>
                            <li>Airframe Parts</li>
                            <li>Engine & Engine Components</li>
                        </ul>
                    </span>
                </div>
                <div className='flex flex-col mx-8 lg:w-1/4 lg:mx-8'>
                    <img src={second} alt="repair" className="my-12 shadow-xl" loading="lazy"/>
                    <span className='mt-4'>
                        <b>Repair, Overhaul & Exchange of Aircraft Components</b><br/>
                        <span>Kindly reach out to us and we will provide solutions to your issue.</span>
                    </span>
                </div>
                <div className='flex flex-col mx-8 lg:w-1/4 lg:mx-8'>
                    <img src={third} alt="unable" className="my-12 shadow-xl" loading="lazy"/>
                    <span className='mt-4'>
                        <b>Didn't Find What You Need?</b><br/>
                        <span>Contact us via phone or email for an immediate response. Otherwise, do enquire by clicking the button below</span>
                    </span>
                </div>
            </div>
            <a href="/contact" className='flex items-center justify-around w-40 mt-16 mx-auto p-2 outline rounded-2xl cursor-pointer hover:bg-green-300'>
                <span>Enquire Now</span>
                <FaChevronRight />
            </a>
        </div>
    </div>
  )
}

export default Fourth